import React from "react";
import {
  Routes, createBrowserRouter, RouterProvider,
  Outlet, BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'

import Home from "views/Home";
import Legal from "views/Legal";
import { TessaelGreenColor } from "components/Style";

const Layout = () => (
  <>
    <Outlet />
  </>
);

const routers = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/legal-notices",
        element: <Legal />,
      }
    ]
  }
]);

const App = () => {
  const theme = createTheme({
    palette: {
      primary: { main: TessaelGreenColor },
      secondary: { main: "#FFF" }
    }
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <RouterProvider router={routers} />
      </ThemeProvider>
    </div>);

  // return (
  //   <ThemeProvider theme={theme}>
  //     <Router>
  //       <div>
  //         <Routes>

  //         <Route exact path="/" component={Home} />
  //         <Route exact path="/legal-notices" component={Legal} />
  //         </Routes>
  //       </div>
  //     </Router>
  //   </ThemeProvider>
  // );
};

export default App;
