// External deps
import React from "react";

import ScrollUpButton from "react-scroll-up-button";

import {
  Button,
  createStyles,
  Divider,
  Link,
  makeStyles
} from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";

// Internal deps
import UnauthenticatedPage from "components/UnauthenticatedPage";
import { Products } from "components/Home/Products";
import ProductCard from "components/Home/ProductCard";
import ProductDescription from "components/Home/ProductDescription";
import OfferCard from "components/Home/OfferCard";
import { Offers } from "components/Home/Offers";
import { Partners } from "components/Home/Partners";
import AboutCard from "components/Home/AboutCard";
import { About } from "components/Home/About";

import { HomeMenuHeaderUnauthenticated } from "components/MenusItems";

// Style
import { GreyTextColor, TessaelGreenColor } from "components/Style";

const useStyles = makeStyles(theme =>
  createStyles({
    about: {
      margin: `${theme.spacing(5)}px auto`,
      textAlign: "center",
      width: "90%"
    },
    aboutText: {
      fontFamily: "'Calibri', sans-serif",
      fontSize: "1.3rem",
      marginBottom: theme.spacing(2),
      color: GreyTextColor,
      maxWidth: "800px",
      margin: "auto"
    },
    aboutTitle: {
      fontFamily: "'Merriweather Sans', sans-serif",
      fontSize: "2rem",
      marginBottom: theme.spacing(5)
    },
    aboutSlogan: {
      fontFamily: "'Calibri', sans-serif",
      fontSize: "1.75rem",
      color: TessaelGreenColor,
      marginBottom: theme.spacing(4)
    },
    aboutUs: {
      display: "flex",
      justifyContent: "space-evenly",
      flexWrap: "wrap",
      marginTop: theme.spacing(6)
    },
    buttonDemo: {
      backgroundColor: "#92d050",
      color: "white",
      fontSize: "1.2rem",
      "&:hover": {
        color: TessaelGreenColor,
        backgroundColor: "white"
      }
    },
    buttonDemoIcon: {
      fontSize: "1.5rem !important"
    },
    divider: {
      width: "10%",
      height: "5px",
      margin: "auto"
    },
    intro: {
      padding: `${theme.spacing(6)}px ${theme.spacing(2)}px ${theme.spacing(
        3
      )}px ${theme.spacing(2)}px`,
      textAlign: "center"
    },
    introCompanyName: {
      fontFamily: "'Merriweather Sans', sans-serif",
      fontSize: "3rem",
      marginBottom: theme.spacing(2)
    },
    introText: {
      fontFamily: "'Calibri', sans-serif",
      fontSize: "1.75rem",
      marginBottom: theme.spacing(4)
    },
    geo2Word: {
      color: TessaelGreenColor,
      fontWeight: "bold"
    },
    needsMessage: {
      padding: theme.spacing(2),
      fontSize: "1.75rem",
      border: `2px solid ${TessaelGreenColor}`,
      height: "80px",
      display: "flex",
      alignItems: "center",
      position: "relative",
      width: "80%",
      maxWidth: "800px",
      color: "white",
      margin: "auto",
      justifyContent: "center",
      fontFamily: "'Merriweather Sans', sans-serif",
      backgroundColor: TessaelGreenColor,
      marginBottom: theme.spacing(8),
      textAlign: "center"
    },
    offers: {
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(6),
      textAlign: "center"
    },
    offersTitle: {
      fontFamily: "'Merriweather Sans', sans-serif",
      fontSize: "2rem",
      marginBottom: theme.spacing(2)
    },
    offersButtons: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap"
    },
    partners: {
      textAlign: "center",
      maxWidth: "1200px",
      margin: `${theme.spacing(5)}px auto`
    },
    partnerLink: {
      width: "100%",
      maxWidth: "230px",
      padding: "0",
      margin: "0"
    },
    partnerLogoImg: {
      height: "80%",
      margin: theme.spacing(3),
      maxHeight: "100%",
      width: "50%",
      padding: "0",
      transition: "transform .3s",
      "&:hover": {
        transform: "scale(1.2)"
      }
    },
    partnersLogos: {
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      minWidth: "200px",
      minWidth: "100px",
      justifyContent: "center"
    },
    partnersTitle: {
      fontFamily: "'Merriweather Sans', sans-serif",
      fontSize: "2rem",
      marginBottom: theme.spacing(5)
    },
    products: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      paddingBottom: theme.spacing(8),
    }
  })
);

const Home = () => {
  const classes = useStyles();

  return (
    <UnauthenticatedPage
      title="Tessael - A Geologic Meshing Company"
      menuHeaderUnauthenticated={HomeMenuHeaderUnauthenticated}
    >
      <div>
        <ScrollUpButton />
      </div>
      <div className={classes.intro}>
        <div className={classes.introCompanyName}><span className={classes.geo2Word}>
          GeO<sub>2</sub>
        </span></div>
        <div className={classes.introText}>
          A modeling-meshing-simulation software platform <br/>for subsurface multi-scale, multi-physics studies{" "}
        </div>
        <div className={classes.introDemo}>
          <Button
            variant="contained"
            className={classes.buttonDemo}
            startIcon={<PlayArrow className={classes.buttonDemoIcon} />}
            href="media/en/presentation_GeO2.mp4"
          >
            Learn More
          </Button>
        </div>
      </div>
      <div className={classes.products}>
        {Products.map(p => {
          return (
            <ProductCard
              image={p.image}
              title={p.title}
              key={p.key}
              href={p.to}
            />
          );
        })}
      </div>
      <a id="products"></a>
      <div className={classes.needsMessage}>How can we help you?</div>
      <Divider className={classes.divider} />
      <div>
        {Products.map(p => {
          return (
            <ProductDescription
              anchor={p.to}
              image={p.image}
              key={p.key}
              title={p.title}
              text={p.text}
            />
          );
        })}
      </div>
      <a id="offers"></a>
      <div className={classes.offers}>
        <div className={classes.offersTitle}>Our offers</div>
        <div className={classes.offersButtons}>
          {Offers.map((o, index) => {
            return (<OfferCard image={o.image} key={index} title={o.title} />);
          })}
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.partners}>
        <div className={classes.partnersTitle}>Partners</div>
        <div className={classes.partnersLogos}>
          {Partners.map(p => {
            return (
              <Link href={p.link}
                key={p.key}
                className={classes.partnerLink}>
                <img src={p.image} className={classes.partnerLogoImg} />
              </Link>
            );
          })}
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.about}>
        <div className={classes.aboutTitle}>About Us</div>
        <div className={classes.aboutSlogan}>
          <p>Unlocking Subsurface's Potential For Energy Transition</p>
        </div>
        <div className={classes.aboutText}>
          <p>
            A spin-off company of Inria, the French National Insitute for
            Research in Digital Science and Technology, we capitalize our
            strong industrial experience and scientific insights to meet your
            subsurface modeling-meshing-simulation needs at different stages of Exploration & Production operations.
          </p>
        </div>
        <a id="about"></a>
        <div className={classes.aboutUs}>
          {About.map(a => {
            return (
              <AboutCard
                key={a.key}
                image={a.image}
                name={a.name}
                title={a.title}
                linkedin={a.linkedin}
              />
            );
          })}
        </div>
      </div>
    </UnauthenticatedPage>
  );
};

export default Home;
