// External deps
import React from "react";

import { Divider, createStyles, makeStyles } from "@material-ui/core";

import { withRouter } from "react-router-dom";
import { GreyTextColor, Tessael } from "components/Style";

// Style

const useStyles = makeStyles(theme =>
  createStyles({
    legalNotice: {
      width: "100%",
      fontFamily: "'Merriweather Sans', sans-serif"
    },
    companyName: {
      fontFamily: "'Merriweather Sans', sans-serif"
    },
    divider: {
      width: "25%",
      height: "5px",
      margin: "auto"
    },
    legalNotice: {
      paddingTop: theme.spacing(3),
    },
    legalBlock: {
      textAlign: "center",
      margin: "auto",
      fontFamily: "'Calibri', sans-serif",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      width: "80%",
    },
    cgu: {
      textAlign: "center",
      margin: "auto",
      fontFamily: "'Calibri', sans-serif",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      width: "60%",
    },
    legalTitle: {
      textAlign: "center",
      //height: "350px",
      margin: "auto",
      width: "80%",
      paddingTop: theme.spacing(3),
      //paddingBottom: theme.spacing(1)
    },
    tessaelName: {
      fontWeight: "bold"
    }
  })
);

export const LegalNotice = props => {
  const classes = useStyles();

  return (
      <div className={classes.legalNotice}>
        <span className={classes.legalTitle}><h2>Mentions légales / Legal notice</h2></span>
        <div className={classes.legalBlock}>
          <h3>Propriétaire / Owner</h3>
            <span className={classes.companyName}>Tessael SAS</span><br/>
            Société au capital de 70k€<br/>
            5 rue Jacques Villermaux<br/>
            54000 Nancy<br/>
          </div>
        <Divider className={classes.divider} />
        <div className={classes.legalBlock}>
          <h3>Hébergeur / Host</h3>
          OVH SAS<br/>
          2 rue Kellermann 59100 Roubaix France<br/>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.cgu}>
          <h3>Condition Générales d'Utilisation (CGU)</h3>
          <p>
          <b>En naviguant sur le présent Site, accessible à l’adresse <a href="https://www.tessael.com/">www.tessael.com</a>, l’Utilisateur accepte expressément être soumis aux présentes conditions générales d’utilisation (CGU) de <span className={classes.tessaelName}>Tessael</span>. L’Utilisateur s’engage à en respecter strictement les termes.</b>
          </p><p>
          Le Site est réservé à l’usage privé de chaque Utilisateur.
          </p><p>
          Le Site et les données, informations et documents qu’il fournit – ou qui sont accessibles via le Site – n’ont qu’une vocation d’information générale non exhaustive sur <span className={classes.tessaelName}>Tessael</span> et ne peuvent en aucun cas être considérés comme constituant un démarchage, une sollicitation et/ou une offre de prestations de services.
          </p><p>
          Chaque Utilisateur est seul responsable de l’usage qu’il fait des informations fournies ou accessibles à partir du Site. <span className={classes.tessaelName}>Tessael</span> ne pourra en aucun cas être tenue responsable de tous dommages directs ou indirects découlant de l’utilisation de ces informations par l’Utilisateur.
          </p><p>
          <span className={classes.tessaelName}>Tessael</span> ne garantit pas que le Site soit accessible de manière continue.
          </p><p>
          Les sites web de tiers, leur contenu et/ou les documents accessibles à partir des liens hypertextes publiés par le Site ou dans les documents téléchargeables à partir de ce dernier, sont la responsabilité exclusive de leur(s) auteur(s) et ne sauraient en aucun cas engager la responsabilité de <span className={classes.tessaelName}>Tessael</span>.
          </p><p>
          Les Utilisateurs ne sont pas autorisés à créer un lien hypertexte vers le Site et/ou une page quelconque du Site et/ou d’un fichier s’y trouvant sans l’accord préalable et écrit de <span className={classes.tessaelName}>Tessael</span>.
          </p><p>
          Le présent Site <a href="https://www.tessael.com/">www.tessael.com</a> contient des informations et des données qui sont protégées par le droit de la propriété intellectuelle, notamment par le droit d’auteur conformément aux articles L.111-1 et suivants du Code de la Propriété Intellectuelle.
          </p><p>
          <b>Ainsi, sauf mention contraire, les droits de propriété intellectuelle sur les documents et toutes informations contenues sur le Site, ainsi que chacun des éléments composant le Site (images, illustrations, sons, textes, éléments graphiques, textes etc.) sont et demeureront la propriété exclusive de <span className={classes.tessaelName}>Tessael</span></b>.
          </p><p>
          <b>Toute reproduction (y compris partiellement, et/ou par voie de transformation ou d’adaptation) est strictement interdite. A défaut, <span className={classes.tessaelName}>Tessael</span> se réserve le droit d’engager toute procédure à l’encontre du contrefacteur.</b>
          </p><p>
          Les présentes CGU sont régies et soumises exclusivement au droit français, et ce, quelle que soit la nationalité de l’Utilisateur.
          </p>
        </div>
      </div>
  );
};
