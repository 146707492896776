export const Offers = [
  {
    image: require("images/user-licenses.png"),
    key: "licenses",
    title: "User licenses"
  },
  {
    image: require("images/consulting.png"),
    key: "people",
    title: "Consulting"
  },
  {
    image: require("images/api.png"),
    key: "api",
    title: "C++ DevKit"
  }
];
