// External deps
import React from "react";

import UnauthenticatedPage from "components/UnauthenticatedPage";
import {LegalMenuHeaderUnauthenticated} from "components/MenusItems";

import { LegalNotice } from "components/Legal/LegalNotice";

const Legal = () => {
  return (
    <UnauthenticatedPage title="Tessael - A Geologic Meshing Company - Legal" menuHeaderUnauthenticated={LegalMenuHeaderUnauthenticated}>
      <LegalNotice />
    </UnauthenticatedPage>
  );
};

export default Legal;
