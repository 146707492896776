export const About = [
  {
    image: require("images/wanchiu-portrait.png"),
    key: "wanchiu",
    name: "Wan-Chiu Li, PhD",
    title: "Cofounder & CEO",
    linkedin: "https://www.linkedin.com/in/wan-chiu-li/"
  },
  {
    image: require("images/cedric-portrait.png"),
    key: "cedric",
    name: "Cédric Borgese",
    title: "Cofounder & CTO",
    linkedin: "https://www.linkedin.com/in/cédric-borgese-95aaab152/"
  }
];
