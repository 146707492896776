import React from "react";

export const Products = [
  {
    name: "flow-simulation",
    image: require("images/flow.gif"),
    key: "RFS",
    description: "SSHex and HexDom meshing solutions",
    text:
      "<div>GeO<sub>2</sub> flow simulation gridding technology assists you in meeting new meshing challenges in your standard or Next-Gen flow simulation workflows.</div><div>Supporting: <ul><li>GEOS</li><li>INTERSECT</li><li>ECLIPSE</li></ul></div>",
    title: "Reservoir Flow Simulation",
    to: "flow-simulation"
  },
  {
    name: "geomechanics-simulation",
    image: require("images/geomechanics.png"),
    key: "RGS",
    description: "Simplex, SSHex and HexDom meshing solutions",
    text:
      "<div>GeO<sub>2</sub> finite-elements meshing technology connects simply your structural models to standard and Next-Gen geomechanical solvers.</div><div>Supporting:<ul><li>GEOS</li><li>ABAQUS</li></ul></div>",
    title: "Geomechanics Simulation",
    to: "geomechanical-simulation"
  },
  {
    name: "flow-geomechanics-simulation",
    image: require("images/flow_geomeca.gif"),
    key: "FGS",
    description: "SSHex and HexDom meshing solutions",
    text:
      "<div>GeO<sub>2</sub> hybrid element meshing technology offers the possiblity to run direct coupled multi-physics studies such as flow-geomechanics on the same grid using powerful state-of-the-art simulators, essential to energy transition applications.</div><div>Supporting:<ul><li>GEOS Full Coupling</li><li>INTERSECT-ABAQUS</li><li>ECLIPSE-ABAQUS</li></ul></div>",
    title: "Flow-Geomechanics Simulation",
    to: "flow-geomechanics-simulation"
  },
  {
    name: "kinematics",
    image: require("images/kinematics.gif"),
    key: "K",
    description: "SSHex and ClipHex meshing solutions",
    text:
      "<div>GeO<sub>2</sub> non-conform hybrid element meshing technology offers the possiblity to run kinematics simulations such as 3D structural restoration and coupled them with multi-physics studies such as thermal-flow-geomechanics on the same grid using powerful simulators.</div><div>Supporting: <ul><li>Kine3D-3</li><li>TemisFlow</li></ul></div>",
    title: "Basin Kinematics Simulation",
    to: "kinematics"
  },
  {
    name: "geomodeling",
    image: require("images/sealedmodel.png"),
    key: "G",
    description: "SealedModel building solution",
    text:
      "<div>GeO<sub>2</sub> geologic modeling building technology offers high performing tools for building complex geologic models.</div><div>Highlights: <ul><li>Fast complex surface reconstruction from point clouds</li><li>Surface cleaning, repairing and remeshing</li><li>Sealed surface structural model building</li></ul></div>",
    title: "Geologic Modeling",
    to: "geomodeling"
  },
  {
    name: "geos-ui",
    image: require("images/geos.png"),
    key: "Geo",
    description: "UI for GEOS simulator",
    text:
      "<div>GeO<sub>2</sub> web-based user interface is the gateway to the powerful GEOS simulator allowing to run multi-physics studies such as direct coupled flow-geomechanics.</div><div>Highlights: <ul><li>Designed for geologic CO<sub>2</sub> storage workflows</li><li>Fast and user-friendly interface to setup, run and visualize simulations</li></ul></div>",
    title: "Run GEOS Simulator",
    to: "geos-ui"
  }
];
