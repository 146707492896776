import React from "react";
import {
  AccountCircle,
  Apps,
  Create,
  Home,
  Info,
  LocalOffer,
  Mail,
  Subject
} from "@material-ui/icons";

export const HomeMenuHeaderUnauthenticated = [
  {
    icon: <Apps />,
    label: "Products",
    to: "#products"
  },
  {
    icon: <LocalOffer />,
    label: "Offers",
    to: "#offers"
  },
  {
    icon: <AccountCircle />,
    label: "Sign In",
    to: "/sign-in",
    disabled: true
  },
  {
    icon: <Create />,
    label: "Register",
    to: "/register",
    disabled: true
  },
  {
    icon: <Info />,
    label: "About",
    to: "#about"
  }
];

export const LegalMenuHeaderUnauthenticated = [
  {
    icon: <AccountCircle />,
    label: "Sign In",
    to: "/sign-in",
    disabled: true
  },
  {
    icon: <Create />,
    label: "Register",
    to: "/register",
    disabled: true
  }
];

export const MenuFooterSocialNetworks = [
  {
    logo: require("images/twitter_logo.png"),
    link: "https://twitter.com/Tessael_sas",
    alt: "twitter"
  },
  {
    logo: require("images/youtube_logo.png"),
    link: "https://www.youtube.com/channel/UCXX6asvlZU9ZkdZ21rXSz5Q",
    alt: "youtube"
  },
  {
    logo: require("images/linkedin_logo.png"),
    link: "https://www.linkedin.com/company/tessael",
    alt: "linkedin"
  }
];

export const MenuFooterUnauthenticated = [
  {
    icon: <Home />,
    label: "Home",
    to: "/"
  },
  {
    icon: <Mail />,
    label: "Contact",
    to: "mailto:contact@tessael.com"
  },
  {
    icon: <Subject />,
    label: "Legal notices",
    to: "/legal-notices"
  }
];
