// External deps
import React from "react";
// import ScrollableAnchor from "react-scrollable-anchor";

import { Button, createStyles, Divider, makeStyles } from "@material-ui/core";

import { GreyTextColor, TessaelGreenColor } from "components/Style";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";
// Style

const useStyles = makeStyles(theme =>
  createStyles({
    contactButton: {
      backgroundColor: "#92d050",
      fontSize: "1rem",
      "&:hover": {
        backgroundColor: "white"
      },
      "& a": {
        color: "white",
        textDecoration: "none"
      },
      "& a:hover": {
        color: TessaelGreenColor
      }
    },
    contactButtonContainer: {
      textAlign: "center"
    },
    divider: {
      height: "5px",
      margin: "auto",
      width: "10%"
    },
    productDescription: {
      width: "90%",
      margin: "auto"
    },
    productDescriptionDetails: {
      display: "flex",
      flexWrap: "wrap",
      fontFamily: "'Calibri', sans-serif",
      width: "100%",
      justifyContent: "space-evenly",
      paddingBottom: theme.spacing(6)
    },
    productDescriptionText: {
      fontSize: "1.3rem",
      marginBottom: theme.spacing(6),
      maxWidth: "800px",
      textAlign: "justify"
    },
    productDescriptionTextContainer: {
      color: GreyTextColor,
      fontSize: "1.6rem"
    },
    productDescriptionTextTitle: {
      fontFamily: "'Merriweather Sans', sans-serif",
      fontSize: "2.2rem",
      fontWeight: "bold",
      padding: `${theme.spacing(6)}px 0px`,
      textAlign: "center"
    },
    productImage: {
      maxHeight: "350px",
      maxWidth: "100%"
    },
    productImageContainer: {
      width: "100%",
      maxWidth: "500px",
      textAlign: "center",
      marginBottom: theme.spacing(2)
    }
  })
);

const ProductDescription = props => {
  const classes = useStyles();

  return (
    <div className={classes.productDescription}>
        <a id={props.anchor}></a>
        <div className={classes.productDescriptionTextTitle}>{props.title}</div>
        <div className={classes.productDescriptionDetails}>
          <div className={classes.productImageContainer}>
            <img src={props.image} className={classes.productImage} />
          </div>
          <div className={classes.productDescriptionTextContainer}>
            <div className={classes.productDescriptionText}>
              {ReactHtmlParser(props.text)}
            </div>
            <div className={classes.contactButtonContainer}>
              <Button variant="contained" className={classes.contactButton}>
                <a href="mailto:contact@tessael.com">Contact us</a>
              </Button>
            </div>
          </div>
        </div>
        <Divider className={classes.divider} />
      </div>
  );
};

export default ProductDescription;
