// External deps
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createStyles,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import MetaTags from "react-meta-tags";

import { Block, Home, Menu } from "@material-ui/icons";

// Internal deps
import {
  MenuFooterUnauthenticated,
  MenuFooterSocialNetworks
} from "components/MenusItems";
import { useCurrentWidth } from "components/CurrentWidth";

// Style
//import "css/page.scss";

import { MenuHeaderFontFamily, TessaelGreenColor } from "components/Style";

const useStyles = makeStyles(theme =>
  createStyles({
    drawerMobile: {
      "& .MuiDrawer-paper": {
        maxWidth: `calc(100% - 75px)`,
        minWidth: "250px",
        color: "white",
        backgroundColor: TessaelGreenColor,
        fontFamily: MenuHeaderFontFamily,
        "& .MuiListItem-button": {
          height: "50px"
        }
      }
    },
    footer: {
      background: "linear-gradient(to right, #00b050, #a9d18e)",
      color: "white",
      fontFamily: MenuHeaderFontFamily,
      fontSize: "1.25rem",
      height: "140px",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    footerLogo: {
      width: "60px",
      position: "relative",
      top: "-25px",
      marginLeft: "auto",
      marginRight: "auto",
      transition: "transform .4s",
      "&:hover": {
        transform: "scale(1.4)"
      }
    },
    footerLogoMobile: {
      width: "60px",
      position: "relative",
      top: "-50px",
      marginLeft: "auto",
      marginRight: "auto",
      transition: "transform .4s",
      "&:hover": {
        transform: "scale(1.4)"
      }
    },
    footerMenu: {
      display: "flex",
      flexDirection: "row",
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3)
    },
    headerDivider: {
      marginLeft: -theme.spacing(5),
      marginBottom: theme.spacing(2),
      width: `calc(100% + ${theme.spacing(10)}px)`
    },
    headerMenu: {
      display: "flex",
      flexDirection: "row",
      fontSize: "1.25rem"
    },
    logo: {
      display: "flex",
      "&:hover": {
        cursor: "pointer"
      },
      alignItems: "center"
    },
    logoImg: {
      width: "40px"
    },
    logoText: {
      fontFamily: "'Merriweather Sans', sans-serif",
      fontSize: "1.5rem",
      marginLeft: theme.spacing(2)
    },
    menuEntryUnderline: {
      height: "2px",
      width: "110%"
    },
    menuFooter: {},
    menuFooterEntry: {
      all: "unset",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      margin: `0 ${theme.spacing(5)}px`,
      "&:hover": {
        cursor: "pointer"
      }
    },
    menuFooterEntryLink: {
      color: "white",
      "&:hover": {
        textDecoration: "none"
      }
    },
    menuFooterEntryUnderlineActive: {
      backgroundColor: "white"
    },
    menuHeaderEntry: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      marginLeft: theme.spacing(5),
      "&:hover": {
        cursor: "pointer"
      },
      fontFamily: MenuHeaderFontFamily
    },
    menuHeaderEntryLink: {
      "&:hover": {
        textDecoration: "none"
      },
      color: TessaelGreenColor
    },
    menuHeaderEntryLabelActive: {
      color: TessaelGreenColor
    },
    menuHeaderEntryUnderlineActive: {
      backgroundColor: TessaelGreenColor
    },
    pageBody: {
      margin: `${theme.spacing(9)}px auto`,
      width: "90%"
    },
    pageHeader: {
      top: 0,
      height: "72px",
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      position: "fixed",
      backgroundColor: "white",
      zIndex: 100,
      width: `calc(100% - ${theme.spacing(10)}px)`
    },
    pageHeaderMenu: {
      alignItems: "center",
      color: TessaelGreenColor,
      display: "flex",
      justifyContent: "space-between",
      height: "72px"
    },
    socialNetworks: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      top: "-10px"
    },
    socialNetworksLogos: {
      height: "20px",
      margin: `0 ${theme.spacing(1.5)}px`,
      transition: "transform .2s",
      "&:hover": {
        transform: "scale(1.4)"
      }
    },
    socialNetworksMobile: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      top: "-20px"
    },
    tooltip: {
      fontSize: "1.25rem"
    }
  })
);

const MenuHeaderEntry = props => {
  const classes = useStyles();

  const [active, setActive] = useState(false);

  const menuHeaderEntryUnderlineClass = clsx(
    classes.menuEntryUnderline,
    active && classes.menuHeaderEntryUnderlineActive
  );
  const menuHeaderEntryLabelClass = clsx(
    classes.menuHeaderEntryLabel,
    active && classes.menuHeaderEntryLabelActive
  );

  return (
    <div
      className={classes.menuHeaderEntry}
      onMouseOver={() => setActive(true)}
      onMouseOut={() => setActive(false)}
    >
      {props.disabled ? (
        <Fragment>
          <Link
            href={props.to}
            className={classes.menuHeaderEntryLink}
            onClick={e => e.preventDefault()}
          >
            <Tooltip
              title={<span className={classes.tooltip}>Coming soon...</span>}
            >
              <div className={menuHeaderEntryLabelClass}>{props.label}</div>
            </Tooltip>
          </Link>
          <span className={menuHeaderEntryUnderlineClass} />
        </Fragment>
      ) : (
        <Fragment>
          <Link href={props.to} className={classes.menuHeaderEntryLink}>
            <div className={menuHeaderEntryLabelClass}>{props.label}</div>
          </Link>
          <span className={menuHeaderEntryUnderlineClass} />
        </Fragment>
      )}
    </div>
  );
};

const MenuFooterEntry = props => {
  const classes = useStyles();
  const [active, setActive] = useState(false);

  const menuFooterEntryUnderlineClass = clsx(
    classes.menuEntryUnderline,
    active && classes.menuFooterEntryUnderlineActive
  );
  const menuFooterEntryLabelClass = clsx(
    classes.menuFooterEntryLabel,
    active && classes.menuFooterEntryLabelActive
  );

  return (
    <div
      className={classes.menuFooterEntry}
      onMouseOver={() => setActive(true)}
      onMouseOut={() => setActive(false)}
    >
      <Link href={props.to} className={classes.menuFooterEntryLink}>
        <div className={menuFooterEntryLabelClass}>{props.label}</div>
      </Link>
      <span className={menuFooterEntryUnderlineClass} />
    </div>
  );
};

const UnauthenticatedPage = props => {
  const classes = useStyles();
  let navigate = useNavigate();

  const width = useCurrentWidth();

  const [drawerMobileOpen, setDrawerMobileOpen] = useState(false);

  const closeDrawerMobile = () => {
    setDrawerMobileOpen(false);
  };

  const openDrawerMobile = () => {
    setDrawerMobileOpen(true);
  };

  const menuDrawer = props.menuHeaderUnauthenticated.filter(item => item.disabled != true).concat(
    MenuFooterUnauthenticated.filter(item => item.label !== "Home")
  );

  if (width < 960) {
    return (
      <Fragment>
        <MetaTags>
          <title>{props.title}</title>
        </MetaTags>
        <div className={classes.page}>
          <div className={classes.pageHeader}>
            <div className={classes.pageHeaderMenu}>
              <div className={classes.drawerMobile} onClick={openDrawerMobile}>
                <IconButton color="primary">
                  <Menu />
                </IconButton>
              </div>
              <div className={classes.logo} onClick={() => navigate("/")}>
                <img
                  className={classes.logoImg}
                  src={require("images/tessael_logo_vector.svg")}
                />
                <span className={classes.logoText}>Tessael</span>
              </div>
            </div>
            <Divider className={classes.headerDivider} />
          </div>
          <div className={classes.pageBody}>{props.children}</div>
        </div>
        <div className={classes.footer}>
          <Link href="/">
            <img
              className={classes.footerLogoMobile}
              alt="Tessael"
              src={require("images/tessael_logo_vector.svg")}
            />
          </Link>
          <div className={classes.socialNetworksMobile}>
            {MenuFooterSocialNetworks.map((item, index) => (
              <Link href={item.link} key={index}>
                <img
                  src={item.logo}
                  
                  alt={item.alt}
                  className={classes.socialNetworksLogos}
                />
              </Link>
            ))}
          </div>
        </div>
        <Drawer
          anchor="left"
          open={drawerMobileOpen}
          onClose={closeDrawerMobile}
          className={classes.drawerMobile}
        >
          <List>
            <ListItem button onClick={() => navigate("/")}>
              <ListItemIcon style={{ color: "white" }}>
                <Home />
              </ListItemIcon>
              <ListItemText disableTypography>Home</ListItemText>
            </ListItem>
            {menuDrawer.map((item, index) => {
              return (
                <Fragment key={index}>
                  <ListItem
                    button
                    
                    component="a"
                    href={item.to}
                    onClick={closeDrawerMobile}
                  >
                    <ListItemIcon style={{ color: "white" }}>
                      {item.disabled ? <Block /> : item.icon}
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {item.label}
                    </ListItemText>
                  </ListItem>
                </Fragment>
              );
            })}
          </List>
        </Drawer>
        <Typography variant="body2" align="center" style={{ color: 'white', marginTop: '-20px' }}>
          {"Copyright © "}
          <Link color="inherit" href="https://tessael.com/">
            Tessael
          </Link>{" "}
          {new Date().getFullYear()}
        </Typography>

      </Fragment>
    );
  }

  return (
    <Fragment>
      <MetaTags>
        <title>{props.title}</title>
      </MetaTags>
      <div className={classes.page}>
        <div className={classes.pageHeader}>
          <div className={classes.pageHeaderMenu}>
            <div className={classes.logo} onClick={() => navigate("/")}>
              <img
                className={classes.logoImg}
                src={require("images/tessael_logo_vector.svg")}
              />
              <span className={classes.logoText}>Tessael</span>
            </div>
            <div className={classes.headerMenu}>
              {props.menuHeaderUnauthenticated.filter(item => item.disabled != true).map(item => (
                <MenuHeaderEntry
                  key={item.label}
                  label={item.label}
                  to={item.to}
                  disabled={item.disabled}
                />
              ))}
            </div>
          </div>
          <Divider className={classes.headerDivider} />
        </div>
        <div className={classes.pageBody}>{props.children}</div>
      </div>
      <div className={classes.footer}>
        <Link href="/">
          <img
            className={classes.footerLogo}
            alt="Tessael"
            src={require("images/tessael_logo_vector.svg")}
          />
        </Link>
        <div className={classes.socialNetworks}>
          {MenuFooterSocialNetworks.map((item, index) => (
            <Link href={item.link} key={index}>
              <img
                src={item.logo}
                alt={item.alt}
                className={classes.socialNetworksLogos}
              />
            </Link>
          ))}
        </div>
        <div className={classes.footerMenu}>
          {MenuFooterUnauthenticated.map(item => (
            <MenuFooterEntry key={item.label} label={item.label} to={item.to} />
          ))}
        </div>
        <Typography variant="body2" align="center" style={{color: 'white', marginTop: '-20px'}}>
          {"Copyright © "}
          <Link color="inherit" href="https://tessael.com/">
            Tessael
          </Link>{" "}
          {new Date().getFullYear()}
        </Typography>
      </div>
    </Fragment>
  );
};

export default UnauthenticatedPage;
