export const Partners = [
  {
    image: require("images/totalenergies.png"),
    link: "https://totalenergies.com/",
    key: "te"
  },
  {
    image: require("images/ul.png"),
    link: "https://www.univ-lorraine.fr/",
    key: "ul"
  },
  {
    image: require("images/inria.png"),
    link: "https://www.inria.fr",
    key: "inria"
  },
  {
    image: require("images/georessources_carnot.png"),
    link: "https://georessources.univ-lorraine.fr/",
    key: "georessources"
  },
  {
    image: require("images/ring.png"),
    link: "http://www.ring-team.org/",
    key: "ring"
  },
  {
    image: require("images/ifpen.png"),
    link: "https://www.ifpenergiesnouvelles.fr/",
    key: "ifpen"
  },
  {
    image: require("images/bpifrance.png"),
    link: "https://www.bpifrance.fr/",
    key: "bpi"
  },
  {
    image: require("images/grand-est.png"),
    link: "https://www.grandest.fr/",
    key: "grand-est"
  },
  {
    image: require("images/Enerex.png"),
    link: "https://enerex.fr/",
    key: "enerex"
  },
  {
    image: require("images/deeplime.png"),
    link: "https://deeplime.io/",
    key: "deeplime"
  }

];
