// External deps
import React from "react";
import {
  createStyles,
  Card,
  CardContent,
  CardMedia,
  Link,
  makeStyles
} from "@material-ui/core";

// Style
import { GreyTextColor, TessaelGreenColor } from "components/Style";

const useStyles = makeStyles(theme =>
  createStyles({
    cardProduct: {
      flexGrow: "1",
      flexShrink: "1",
      flexBasis: "180px",
      maxWidth: "400px",
      width: "100%",
      minWidth: "300px",
      transition: "transform .2s",
      "&:hover": {
        transform: "scale(1.1)",
        backgroundColor: "white",
        cursor: "pointer",
        color: TessaelGreenColor,
        opacity: 1
      },
      "& > a:hover": {
        textDecoration: "none"
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: theme.spacing(3),
      opacity: 0.95
    },
    cardProductImage: {
      flexGrow: "1",
      flexShrink: "1",
      flexBasis: "150px",
      minHeight: "150px",
      maxHeight: "90%",
      backgroundSize: "contain"
    },
    cardProductText: {
      textAlign: "center",
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginTop: theme.spacing(2),
      fontFamily: "'Merriweather Sans', sans-serif",
      color: GreyTextColor
    }
  })
);

const ProductCard = props => {
  const classes = useStyles();

  return (
    <Card className={classes.cardProduct}>
      <Link href={`#${props.href}`}>
        <CardMedia component="img" className={classes.cardProductImage} image={props.image} />
        <CardContent className={classes.cardProductText}>
          {props.title}
        </CardContent>
      </Link>
    </Card>
  );
};

export default ProductCard;
