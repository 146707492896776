// External deps
import React from "react";
import { createStyles, Link, makeStyles } from "@material-ui/core";

// import { useParams } from 'react-router-dom';

// Style
import { GreyTextColor, TessaelGreenColor } from "components/Style";

const useStyles = makeStyles(theme =>
  createStyles({
    aboutCard: {
      margin: `${theme.spacing(4)}px ${theme.spacing(2)}px`
    },
    aboutNameText: {
      textAlign: "center",
      fontSize: "1.8rem",
      fontWeight: "bold",
      marginTop: theme.spacing(2),
      fontFamily: "'Merriweather Sans', sans-serif",
      color: GreyTextColor
    },
    aboutPortrait: {
      height: "200px",
      transition: "transform .2s",
      "&:hover": {
        transform: "scale(1.1)"
      }
    },
    aboutImageText: {
      padding: "5px 0px 5px 0px"
    },
    aboutTitleText: {
      textAlign: "center",
      fontSize: "1.0rem",
      fontWeight: "bold",
      marginTop: theme.spacing(2),
      fontFamily: "'Merriweather Sans', sans-serif",
      color: GreyTextColor
    },
    aboutLinkedLink: {}
  })
);

const AboutCard = props => {
  const classes = useStyles();

  return (
    <div className={classes.aboutCard}>
      <Link className={classes.aboutLinkedLink} href={props.linkedin}>
        <img
          src={props.image}
          alt={props.name}
          className={classes.aboutPortrait}
        />
      </Link>
      <div className={classes.aboutImageText}>
        <div className={classes.aboutNameText}>{props.name}</div>
        <div className={classes.aboutTitleText}>{props.title}</div>
      </div>
    </div>
  );
};

export default AboutCard;
